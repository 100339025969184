import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
// import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
// SyntaxHighlighter.registerLanguage('javascript', js);
const GeoPage = ({ location }) => {
  const title = 'Geo IP Databases';

  return (
    <Layout location={location} title={title}>
      <h1>{title}</h1>
      <h2>IP database</h2>
      <p>
        Determine the continent, country, subdivisions (regions) associated with
        IPv4 and IPv6 addresses worldwide. This database is based on data from
        MaxMind's GeoLite2 City database. It does not contain latitude,
        longitude, postal code or city names.
      </p>
      <p>
        Our latest build is available from the following url:
        <br />
        <Link to="https://composer.analys.cloud/geo/Whitespace-Geo-City-latest.mmdb.gz">{`https://composer.analys.cloud/geo/Whitespace-Geo-City-latest.mmdb.gz`}</Link>
      </p>

      <p>
        This product includes GeoLite2 data created by MaxMind, available from
        {` `}
        <a href="https://www.maxmind.com">https://www.maxmind.com</a>.
      </p>
      <h3>Examples</h3>
      <table>
        <thead>
          <th>Title</th>
          <th>IP</th>
          <th>Region name</th>
          <th>Region code</th>
          <th>Country name</th>
          <th>Country code</th>
          <th>Continent name</th>
          <th>Contintent code</th>
        </thead>
        <tbody>
          <tr>
            <td>Whitespace Office</td>
            <td>213.158.37.2</td>
            <td>Skåne County</td>
            <td>M</td>
            <td>Sweden</td>
            <td>SE</td>
            <td>Europe</td>
            <td>EU</td>
          </tr>
          <tr>
            <td>Google</td>
            <td>8.8.8.8</td>
            <td>-</td>
            <td>-</td>
            <td>United States</td>
            <td>US</td>
            <td>North America</td>
            <td>NA</td>
          </tr>
        </tbody>
      </table>
      <h3>Installation</h3>
      <ol>
        <li>Go to Administration > System > Geolocation</li>
        <li>Select DBIP / GeoIP 2</li>
        <li>
          Add the{' '}
          <Link to="https://composer.analys.cloud/geo/Whitespace-Geo-City-latest.mmdb.gz">
            url
          </Link>
          {` `}to input field "Download URL"
        </li>
        <li>
          Add the following to config.ini.php
          <SyntaxHighlighter language="bash">
            {`geolocation_download_from_trusted_hosts[] = composer.analys.cloud`}
          </SyntaxHighlighter>
        </li>
        <li>
          Run the following command to download and update the geo database in
          your Matomo installation:
          <SyntaxHighlighter language="bash">
            {`./console scheduled-tasks:run "Piwik\\Plugins\\GeoIp2\\GeoIP2AutoUpdater.update"`}
          </SyntaxHighlighter>
        </li>
      </ol>
      <h2>Other Geo IP Sources</h2>
      <ul>
        <li>
          MaxMind - <Link to="https://www.maxmind.com/">{`maxmind.com`}</Link>
        </li>
        <li>
          DB IP - <Link to="https://db-ip.com/">{`db-ip.com`}</Link>
        </li>
        <li>
          IP 2 location -{' '}
          <Link to="https://www.ip2location.com/">{`ip2location.com`}</Link>
        </li>
      </ul>
    </Layout>
  );
};

export default GeoPage;
